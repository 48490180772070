import React from 'react'

const STALE_THRESHOLD = 10 * 1000 // 10 seconds to consider a tab stale
const HEARTBEAT_INTERVAL = 3 * 1000 // 3 second
const TAB_IDENTIFIER = 'assessment_tab_'
const URL_PATTERN = /^\/patients\/[a-f0-9-]{36}\/assessments\/[a-zA-Z]+\/[a-f0-9-]{36}$/

export const isAssessmentUrl = (): boolean => {
  const currentUrl = window.location.pathname
  return URL_PATTERN.test(currentUrl)
}
export const registerAssessmentTab = (
  tabId: string,
  url: string,
  ref: React.MutableRefObject<NodeJS.Timer | null>
): void => {
  // Function to update the heartbeat
  if (!isAssessmentUrl()) {
    return
  }

  const updateHeartbeat = (): void => {
    localStorage.setItem(`${TAB_IDENTIFIER}|${url}|${tabId}`, Date.now().toString())
  }
  updateHeartbeat() // Set initially

  // Periodically send heartbeats
  if (ref.current) {
    clearInterval(ref.current)
  }
  ref.current = setInterval(updateHeartbeat, HEARTBEAT_INTERVAL)

  // Clean up when the tab is closed
  window.addEventListener('beforeunload', function () {
    if (ref.current) {
      clearInterval(ref.current)
    }

    if (!isAssessmentUrl()) {
      return
    }
    localStorage.removeItem(`${TAB_IDENTIFIER}|${url}|${tabId}`) // Remove this tab's data
  })
}

export const cleanUpStaleAssessmentTab = (
  tabId: string,
  urlPathName: string,
  setHasOpened: (state: any) => void,
  ref: React.MutableRefObject<NodeJS.Timer | null>
): void => {
  const checkAndCleanStaleTabs = (): void => {
    const now = Date.now()
    let hasOpened = false
    // Iterate through localStorage and check for stale assessment tabs
    Object.keys(localStorage).forEach((key: string) => {
      if (
        key.startsWith(TAB_IDENTIFIER) &&
        key.includes(urlPathName) &&
        URL_PATTERN.test(urlPathName) &&
        !key.includes(tabId)
      ) {
        const value = localStorage.getItem(key)
        if (!value) {
          return
        }
        const lastHeartbeat = parseInt(value, 10)
        if (now - lastHeartbeat > STALE_THRESHOLD) {
          // If the heartbeat is too old, consider it stale and remove it
          localStorage.removeItem(key)
          hasOpened = false
        } else {
          hasOpened = true
          // If the heartbeat is recent, consider the tab as opened and immediately update state
          setHasOpened((prev) => ({ ...prev, assessment: true }))
        }
      }
    })

    setHasOpened((prev) => ({ ...prev, assessment: hasOpened }))
  }

  checkAndCleanStaleTabs() // Check initially

  // Periodically check and clean up stale data
  if (ref.current) {
    clearInterval(ref.current)
  }
  ref.current = setInterval(checkAndCleanStaleTabs, HEARTBEAT_INTERVAL)

  // Clean up when the tab is closed
  window.addEventListener('beforeunload', function () {
    if (ref.current) {
      clearInterval(ref.current)
    }
  })
}
