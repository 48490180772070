import React, { useEffect, useMemo, useRef } from 'react'

import { Alert, Modal, Typography } from 'antd'
import { useRouter } from 'next/router'
import { v4 as uuidv4 } from 'uuid'
import { cleanUpStaleAssessmentTab, registerAssessmentTab } from 'utils/tabValidator'

const { Text } = Typography

const tabId = uuidv4()

export function OpenedAssessmentTabModal(): JSX.Element {
  const { asPath } = useRouter()

  const registerAssesmentTabRef = useRef(null)
  const cleanUpStaleAssessmentTabRef = useRef(null)
  const [isUrlOpened, setIsUrlOpened] = React.useState({
    assessment: false
  })

  const currPathname = useMemo(() => asPath.split('?')[0], [asPath])

  useEffect(() => {
    if (currPathname !== '/') {
      registerAssessmentTab(tabId, window.location.href, registerAssesmentTabRef)
      cleanUpStaleAssessmentTab(tabId, currPathname, setIsUrlOpened, cleanUpStaleAssessmentTabRef)
    }
  }, [currPathname])

  return (
    <Modal
      open={isUrlOpened.assessment}
      bodyStyle={{ padding: '32 32 24 32' }}
      closable={false}
      footer={null}
    >
      <Text strong>Warning!</Text>
      <Alert showIcon type="error" message={<BannerCopy />} style={{ margin: '16px 0 0' }} />
    </Modal>
  )
}

function BannerCopy(): JSX.Element {
  return (
    <div>
      <b>This assessment is opened in another tab.</b> If you would like to edit the assessment in
      this tab, please close all other windows to prevent losing any charting progress.
    </div>
  )
}
