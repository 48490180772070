import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { ParsedUrlQuery } from 'querystring'
import { UrlObject } from 'url'

export interface UseSafeNavigation {
  safePush: (pathname: UrlObject | string, as?: UrlObject | string, options?: PushOptions) => void
  safeReplace: (
    pathname: UrlObject | string,
    as?: UrlObject | string,
    options?: PushOptions
  ) => void
  pathname: string
  query: ParsedUrlQuery
  back: () => void
}

interface PushOptions {
  scroll?: boolean
  shallow?: boolean
  locale?: string
}

/**
 * Listens to NextJS's routeChangeComplete event so we can track and handle route changes safely.
 * This allows us to determine if the router is currently changing routes and perform specific actions accordingly.
 * Will prevent accidental calls to 'push' during a route change which can commonly happen in re-renders.
 * @returns void
 */
const useSafeNavigation = (): UseSafeNavigation => {
  const [isNavigating, setIsNavigating] = useState(false)
  const { push, replace, events, pathname, query, back } = useRouter()

  const safePush = useCallback(
    (path: UrlObject | string, as: UrlObject | string = '', options: PushOptions = {}) => {
      const pathname = typeof path === 'string' ? path : path.pathname

      if (isNavigating) {
        console.warn(
          `[Safe Navigation] Attempted to navigate to ${pathname} but a navigation change is already in progress.`
        )

        return
      }

      setIsNavigating(true)

      console.info(`[Safe Navigation] Routing to ${pathname}`)

      push(path, as, options).then(() => {
        setIsNavigating(false)
        console.info('[Safe Navigation] Routing complete')
      })
    },
    [isNavigating, push]
  )

  const safeReplace = useCallback(
    (path: UrlObject | string, as: UrlObject | string = '', options: PushOptions = {}) => {
      const pathname = typeof path === 'string' ? path : path.pathname

      if (isNavigating) {
        console.warn(
          `[safe navigation] Attempted to replace to ${pathname} but a navigation change is already in progress.`
        )
        return
      }

      setIsNavigating(true)

      console.info(`[Safe Navigation] Routing to ${pathname}`)

      replace(path, as, options).then(() => {
        setIsNavigating(false)
        console.info('[Safe Navigation] Routing complete')
      })
    },
    [isNavigating, replace]
  )

  useEffect(() => {
    const handleRouteChangeComplete = (): void => {
      if (isNavigating) {
        setIsNavigating(false)
      }
    }

    events.on('routeChangeComplete', handleRouteChangeComplete)
    events.on('routeChangeError', handleRouteChangeComplete)

    return () => {
      events.off('routeChangeComplete', handleRouteChangeComplete)
      events.off('routeChangeError', handleRouteChangeComplete)
    }
  }, [events, isNavigating])

  return { safePush, safeReplace, pathname, query, back }
}

export default useSafeNavigation
