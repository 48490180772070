// DEPRECATED: STOP ADDING TO THIS - USE A MORE SPECIFIC UTILS FILE INSTEAD
import { ParsedUrlQuery } from 'querystring'
import { Maybe } from 'generated/graphql'
import dayjs, { Dayjs } from 'dayjs';

export const findValueBySystem = <T extends { system?: Maybe<string>; value?: Maybe<string> }>(
  arr: T[],
  system: string
): string | undefined => {
  return arr?.find((item) => item.system === system)?.value ?? undefined
}

export const findIndexOfValueBySystem = <
  T extends { system?: Maybe<string>; value?: Maybe<string> }
>(
  arr: T[],
  system: string
): number | undefined => {
  return arr?.findIndex((item) => item.system === system) ?? undefined
}

export const capitalize = (str = ''): string => {
  return str
    .toLowerCase()
    .replace(/[-_]/g, ' ')
    .replace(/(^|\s)\S/g, (letter) => letter.toUpperCase())
}

export const coalesceArray = <T>(arr: (T | null)[]): T[] => {
  return arr.filter((element): element is T => element !== null && element !== undefined)
}

export const joinWithAnd = (elements: string[]): string => {
  const length = elements.length
  if (length === 0) return ''
  if (length === 1) return elements[0]

  const nonLastElements = elements.slice(0, length - 1)
  const lastElement = elements[length - 1]

  return coalesceArray([nonLastElements.join(', '), lastElement]).join(' and ')
}

export const truncateWithEllipses = (str: string, maxLength: number): string => {
  if (str.length <= maxLength) return str
  return str.slice(0, maxLength - 3) + '...'
}

export const findTargetProperty = (obj: Record<string, any>, targetProperty: string): any => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (key === targetProperty) {
        return obj[key]
      } else if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        return findTargetProperty(obj[key], targetProperty)
      }
    }
  }
}

export const removeNullValues = (obj: Record<any, any>): Record<any, any> | null => {
  if (typeof obj !== 'object' || obj instanceof dayjs || obj === null) {
    return obj
  }
  if (Array.isArray(obj)) {
    // filter out values that are returned as null, such as null in a list of objects or empty object
    return obj.map(removeNullValues).filter((value) => value !== null)
  }

  const objectEntries = Object.entries(obj)
    // we have undefined valueX answers in the answer array from upstream code, e.g. buildDefaultSoc and buildDefaultRecert, we need to filter out undefined values as well as null values.
    .filter(([_, value]) => value !== null && value !== undefined)
    .map(([key, value]) => [key, removeNullValues(value)])

  if (objectEntries.length === 0) {
    return null
  }
  return Object.fromEntries(objectEntries)
}

export const getQueryValues = (query: ParsedUrlQuery, key: string): string[] => {
  const queryValue = query[key]
  if (typeof queryValue === 'string') {
    return [queryValue]
  } else if (!queryValue) {
    return []
  } else {
    return queryValue
  }
}

export const getSingleQueryValue = (query: ParsedUrlQuery, key: string): string | undefined => {
  const queryValues = getQueryValues(query, key)
  return queryValues.shift()
}

export const getCssSelectorForLinkId = (value): string => {
  // Replace non-alphanumeric characters with underscores
  return value.replace(/[^a-zA-Z0-9-_]/g, '_')
}

export const getErrorInstance = (
  e: Error | string | unknown,
  message = 'An unexpected error occurred'
): Error => {
  let errorMessage = `${message}: ${e}`
  if (e instanceof Error) {
    errorMessage = `${message}: ${e.message}`
  } else if (typeof e === 'string') {
    errorMessage = e
  }

  return new Error(errorMessage)
}

export const getGraphQLErrorMessage = (
  errors: [Record<string, any>],
  message = 'There was an error updating the resource. Please try again.'
): string => errors?.map((e) => e.message).join(', ') ?? message

export const isJSXElement = (value: any): value is JSX.Element => {
  return typeof value === 'object' && value !== null && 'type' in value
}

export const US_STATE_TO_ABBREV = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
  'District of Columbia': 'DC',
  'American Samoa': 'AS',
  Guam: 'GU',
  'Northern Mariana Islands': 'MP',
  'Puerto Rico': 'PR',
  'United States Minor Outlying Islands': 'UM',
  'U.S. Virgin Islands': 'VI'
}

export const convertStateToAbbreviation = (state: string): string | undefined => {
  if (Object.values(US_STATE_TO_ABBREV).includes(state)) return state

  return US_STATE_TO_ABBREV[state]
}
