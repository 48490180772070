import AbbyCare from './abby-care.svg'
import AlertTriangle from './alert-triangle.svg'
import Announcement from './announcement.svg'
import ArrowCircleUpRightBtn from './arrow-circle-up-right-btn.svg'
import ArrowCircleUpRight from './arrow-circle-up-right.svg'
import ArrowRightOutlined from './arrow-right-outlined.svg'
import AtSign from './at-sign.svg'
import BarChart from './bar-chart.svg'
import Bell from './bell.svg'
import BuildingBlueCircle from './building-blue-circle.svg'
import Calendar from './calendar.svg'
import Checkmark from './checkmark.svg'
import ClipboardBlueCircle from './clipboard-blue-circle.svg'
import ClockCheck from './clock-check.svg'
import CloudUpload from './cloud-upload.svg'
import CompassBlueCircle from './compass-blue-circle.svg'
import Copy from './copy.svg'
import Delete from './delete.svg'
import DotsHorizontal from './dots-horizontal.svg'
import DownOutlined from './down-outlined.svg'
import Download01 from './download-01.svg'
import Edit from './edit.svg'
import ExclamationCircle from './exclamation-circle.svg'
import Eye from './eye.svg'
import FileHeartBlueCircle from './file-heart-blue-circle.svg'
import FilePlus from './file-plus.svg'
import FilterLines from './filter-lines.svg'
import HeartHand from './heart-hand.svg'
import HeartWave from './heart-wave.svg'
import Heart from './heart.svg'
import Home from './home.svg'
import AlertCircle from './alert-circle.svg'
import InfoCircle from './info-circle.svg'
import MarkerPin from './marker-pin.svg'
import MedicalCross from './medical-cross.svg'
import Menu from './menu.svg'
import MinusOutlined from './minus-outlined.svg'
import PaperClipOutlined from './paper-clip.svg'
import Payroll from './payroll.svg'
import Pen from './pen.svg'
import Pencil from './pencil.svg'
import People from './people.svg'
import PhoneBlueCircle from './phone-blue-circle.svg'
import PlusOutlined from './plus-outlined.svg'
import ProfileBlueCircle from './profile-blue-circle.svg'
import QuestionCircleOutlined from './question-circle-outlined.svg'
import SearchSm from './search-sm.svg'
import Search from './search.svg'
import Send01 from './send-01.svg'
import Send03 from './send-03.svg'
import Settings from './settings.svg'
import SwitchHorizontal02 from './switch-horizontal-02.svg'
import Target from './target.svg'
import Trash01 from './trash-01.svg'
import UploadFileCircle from './upload-file-circle.svg'
import User01 from './user-01.svg'
import UserDown01 from './user-down-01.svg'
import UserRight01 from './user-right-01.svg'
import UserOutlined from './user-outlined.svg'
import User from './user.svg'
import XClose from './x-close.svg'
import ClipboardPlus from './clipboard-plus.svg'
import AbbyCareFrame from './abby-care-frame.svg'
import ArrowsRight from './arrows-right.svg'
import ArrowCircleBrokenUpRight from './arrow-circle-broken-up-right.svg'
import LinkExternal from './link-external.svg'

export {
  AbbyCare,
  AlertCircle,
  AlertTriangle,
  Announcement,
  ArrowCircleUpRight,
  ArrowCircleUpRightBtn,
  ArrowRightOutlined,
  AtSign,
  BarChart,
  Bell,
  BuildingBlueCircle,
  Calendar,
  Checkmark,
  ClipboardBlueCircle,
  ClockCheck,
  CloudUpload,
  CompassBlueCircle,
  Copy,
  Delete,
  DotsHorizontal,
  DownOutlined,
  Download01,
  Edit,
  ExclamationCircle,
  Eye,
  FileHeartBlueCircle,
  FilePlus,
  FilterLines,
  Heart,
  HeartHand,
  HeartWave,
  Home,
  InfoCircle,
  MarkerPin,
  MedicalCross,
  Menu,
  MinusOutlined,
  PaperClipOutlined,
  Payroll,
  Pen,
  Pencil,
  People,
  PhoneBlueCircle,
  PlusOutlined,
  ProfileBlueCircle,
  QuestionCircleOutlined,
  Search,
  SearchSm,
  Send01,
  Send03,
  Settings,
  SwitchHorizontal02,
  Target,
  Trash01,
  UploadFileCircle,
  User,
  User01,
  UserDown01,
  UserRight01,
  UserOutlined,
  XClose,
  ClipboardPlus,
  AbbyCareFrame,
  ArrowsRight,
  ArrowCircleBrokenUpRight,
  LinkExternal
}
